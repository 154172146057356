.cookie-notice {
    @include transition-duration(0.3s);
    @include transition-property(bottom, visibility, z-index);
    @include transition-timing(cubic-bezier(.55, .06, .68, .19));
    background-color: $cookie-background-color;
    bottom: 15px;
    box-shadow: 0 0 10px rgba($cookie-background-color, 0.6);
    color: $cookie-text-color;
    left: 15px;
    padding: 55px 20px 20px;
    position: fixed;
    right: 15px;
    visibility: visible;
    z-index: 1;
    @media (min-width: $screen-sm-min) {
        left: auto;
        width: 320px;
    }
    &.disabled {
        bottom: -300px;
        visibility: hidden;
        z-index: 0;
    }
    p {
        font-size: 14px;
        margin: 0;
        a {
            color: $cookie-icon;
        }
    }
    .icon {
        background: $cookie-icon;
        border-radius: 50%;
        color: $cookie-text-color;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 20px;
        font-style: italic;
        font-weight: bold;
        height: 30px;
        left: 0;
        line-height: 30px;
        margin: 0 auto;
        position: absolute;
        right: 0;
        text-align: center;
        top: 15px;
        width: 30px;
    }
    .close-cookie-notice {
        cursor: pointer;
        height: 30px;
        position: absolute;
        right: 5px;
        top: 5px;
        width: 30px;
        &::before,
        &::after {
            background-color: $cookie-close-notice;
            bottom: 0;
            content: "";
            height: 2px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 20px;
        }
        &::before {
            @include transform-rotate(45deg);
        }
        &::after {
            @include transform-rotate(-45deg);
        }
    }
}