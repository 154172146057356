.testimonials {
    margin-bottom: 80px;
    text-align: center;
    > div:nth-last-child(1),
    > div:nth-last-child(2) {
        margin-top: 50px;
    }
    .avatar {
        background-image: url($image-path + "avatars-mobile.jpg");
        background-repeat: no-repeat;
        height: 240px;
        margin: 0 auto 10px auto;
        width: 223px;
        &.person1 {
            background-position: 0 center;
        }
        &.person2 {
            background-position: -223px center;
        }
        &.person3 {
            background-position: -446px center;
        }
    }
    .blockquote {
        color: $text-color-default;
        font-size: 16px;
        padding: 0 25px;
        position: relative;
        &::before {
            color: $color-primary;
            content: "\f10d";
            font-family: "FontAwesome";
            left: 0;
            position: absolute;
            top: 0;
        }
    }
    .more {
        background-color: $background-color-primary;
        border-radius: 0px;
        border-width: 0px;
        color: $text-color-default;
        font-size: 15px;
        margin-top: 15px;
        padding: 16px 32px;
        text-transform: lowercase;
    }
    @media (min-width: $screen-sm-min) {
        > div:nth-last-child(1),
        > div:nth-last-child(2) {
            margin-top: 0;
        }
        .avatar {
            background-image: url($image-path + "avatars-desktop.jpg");
            height: 170px;
            width: 158px;
            &.person1 {
                background-position: 0 center;
            }
            &.person2 {
                background-position: -158px center;
            }
            &.person3 {
                background-position: -316px center;
            }
        }
    }
}