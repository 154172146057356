article {
    a {
        color: $link-color;
        &:focus,
        &:hover {
            color: $article-link-hover-color;
        }
    }
    header {
        background-image: url($image-path + "header-background-mobile.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 491px;
        margin-bottom: 120px;
        margin-left: -15px;
        margin-right: -15px;
        position: relative;
        @media (min-width: $screen-sm-min) {
            background-image: url($image-path + "header-background-desktop.jpg");
            height: 556px;
        }
        .article-heading-content {
            background-color: $background-color-primary;
            bottom: -70px;
            left: 0;
            margin: auto;
            padding: 50px 30px;
            position: absolute;
            right: 0;
            text-align: center;
            width: 88%;
            &::before {
                @include box-shadow-left(5%, 45.2%);
            }
            &::after {
                @include box-shadow-right(5%, 45.2%);
            }
            @media (min-width: $screen-md-min) {
                padding: 50px 380px 50px 70px;
                text-align: left;
            }
            h1 {
                color: $text-color-default;
                font-size: 36px;
                font-weight: 700;
                margin: 0 0 15px 0;
                text-transform: uppercase;
                word-wrap: break-word;
                span {
                    display: block;
                    font-size: 26px;
                    text-transform: none;
                }
            }
            .lead {
                color: $text-color-default;
                font-size: 15px;
            }
            img {
                height: 92px;
                @media (min-width: $screen-md-min) {
                    bottom: 40px;
                    height: auto;
                    position: absolute;
                    right: 0px;
                }
            }
        }
    }
    footer {
        margin-left: -15px;
        margin-right: -15px;
        .article-footer-content {
            background-color: $background-color-primary;
            margin: 30px auto 70px auto;
            padding: 50px 30px;
            position: relative;
            text-align: center;
            width: 88%;
            &::before {
                @include box-shadow-left(5%, 45.2%);
            }
            &::after {
                @include box-shadow-right(5%, 45.2%);
            }
            @media (min-width: $screen-md-min) {
                padding: 50px 70px 50px 380px;
                text-align: left;
            }
            h2 {
                color: $text-color-default;
                font-weight: 700;
                margin: 0 0 15px 0;
                word-wrap: break-word;
            }
            p {
                color: $text-color-default;
                font-size: 15px;
                margin: 0;
                a {
                    color: $color-white;
                }
                .contact-email {
                    display: block;
                    font-size: 22px;
                    margin-top: 15px;
                }
            }
            img {
                margin: 0 auto 30px auto;
                @media (min-width: $screen-md-min) {
                    bottom: 0;
                    left: 60px;
                    margin: auto;
                    position: absolute;
                    top: 0;
                }
            }
        }
    }
}