.slider {
    background-image: url($image-path + "header-background-mobile.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 491px;
    margin-bottom: 70px;
    position: relative;
    @media (min-width: $screen-sm-min) {
        background-image: url($image-path + "header-background-desktop.jpg");
        height: 556px;
    }
}

.slider-inner {
    background-color: $background-color-primary;
    bottom: -70px;
    left: 0;
    margin: auto;
    padding: 50px 30px;
    position: absolute;
    right: 0;
    width: 88%;
    .item {
        display: none;
        >img {
            display: none;
        }
        &.active {
            display: block;
        }
    }
    &::before {
        @include box-shadow-left(5%, 45.2%);
    }
    &::after {
        @include box-shadow-right(5%, 45.2%);
    }
    @media (min-width: $screen-sm-min) {
        height: 400px;
        padding: 50px;
        .item {
            @include transition-delay(0s);
            @include transition-duration(0.5s);
            @include transition-property(opacity, left);
            @include transition-timing(ease-in-out);
            display: block;
            left: -20px;
            margin: 50px;
            opacity: 0;
            position: absolute;
            top: 0;
            z-index: -1;
            &.active {
                left: 0;
                opacity: 1;
                z-index: 0;
            }
            >img {
                display: block;
                opacity: 0;
                position: absolute;
                right: -100px;
                top: -150px;
                z-index: -1;
            }
        }
        .active {
            img {
                opacity: 1;
                z-index: 0;
            }
        }
    }
    @media (min-width: $screen-md-min) {
        height: 350px;
        padding: 50px 70px;
        .item {
            margin: 50px 70px;
            >img {
                right: -120px;
                top: -197px;
            }
        }
    }
}

.slider-caption {
    text-align: center;
    @media (min-width: $screen-sm-min) {
        text-align: left;
        width: 60%;
    }
    @media (min-width: $screen-md-min) {
        width: 70%;
    }
    @media (min-width: $screen-lg-min) {
        width: 75%;
    }
    h2 {
        color: $text-color-default;
        font-size: 36px;
        font-weight: 700;
        margin: 0 0 15px 0;
        text-transform: uppercase;
        word-wrap: break-word;
        span {
            display: block;
            font-size: 26px;
            text-transform: none;
        }
    }
    p {
        color: $text-color-default;
        font-size: 15px;
    }
    .more {
        background-color: $background-color-default;
        border-radius: 0px;
        border-width: 0px;
        color: $text-color-white;
        font-size: 15px;
        margin-top: 20px;
        padding: 16px 40px;
        text-transform: lowercase;
    }
}

.slider-indicators {
    display: none;
    @media (min-width: $screen-sm-min) {
        bottom: 50px;
        display: block;
        left: 0;
        list-style-image: none;
        list-style-position: outside;
        list-style-type: none;
        margin: 0 auto;
        overflow: hidden;
        padding: 0;
        position: absolute;
        right: 0;
        width: 156px;
        li {
            border-color: $color-white;
            border-radius: 50%;
            border-style: solid;
            border-width: 2px;
            color: $text-color-default;
            cursor: pointer;
            float: left;
            font-size: 15px;
            font-weight: 700;
            height: 32px;
            line-height: 26px;
            margin: 0 10px;
            text-align: center;
            width: 32px;
            &.active {
                background-color: $background-color-white;
            }
        }
    }
}