.our-advantages {
    background-color: $background-color-default;
    margin-left: 15px;
    margin-right: 15px;
    padding: 40px 15px;
    position: relative;
    &::before {
        @include box-shadow-left(5%, 45.2%);
    }
    &::after {
        @include box-shadow-right(5%, 45.2%);
    }
    h3 {
        color: $text-color-primary;
        margin-top: 0;
    }
    .item {
        border-radius: 50%;
        border: 5px solid $background-color-primary;
        height: 230px;
        margin: 0 auto 20px auto;
        overflow: hidden;
        position: relative;
        width: 230px;
        &::before {
            background-image: url($image-path + "our-advantages.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 50%;
            bottom: 0;
            content: "";
            left: 0;
            margin: 5px;
            position: absolute;
            right: 0;
            top: 0;
        }
        &.discreet-parcel,
        &.specialist-support {
            margin-top: 50px;
        }
        &.best-price::before {
            background-position: 0 center;
        }
        &.discreet-parcel::before {
            background-position: -210px center;
        }
        &.specialist-support::before {
            background-position: -420px center;
        }
        h4 {
            bottom: 0;
            color: $text-color-white;
            font-size: 26px;
            height: 57px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            text-align: center;
            text-transform: uppercase;
            top: 0;
            span {
                display: block;
            }
        }
    }
    .caption {
        color: $text-color-white;
        font-size: 15px;
        padding: 0 20px;
        text-align: center;
    }
    .visit-store {
        background-image: url($image-path + "visit-store.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 40px;
        padding: 80px 30px;
        text-align: center;
        >img {
            display: block;
            margin: 0 auto;
        }
        .store-logo {
            a {
                display: inline-block;
            }
        }
        .more {
            background-color: $background-color-primary;
            color: $text-color-default;
            border-radius: 0px;
            border-width: 0px;
            font-size: 26px;
            margin-top: 50px;
            padding: 20px 30px;
            text-transform: lowercase;
        }
    }
    .links {
        text-align: center;
        .more {
            background-color: $background-color-primary;
            border-radius: 0px;
            border-width: 0px;
            color: $text-color-default;
            display: block;
            font-size: 15px;
            margin: 40px auto 0 auto;
            padding: 20px 0;
            text-transform: lowercase;
            width: 220px;
        }
    }
    @media (min-width: $screen-sm-min) {
        margin-left: auto;
        margin-right: auto;
        .item {
            height: 210px;
            width: 210px;
            &.discreet-parcel,
            &.specialist-support {
                margin-top: 0;
            }
            &.discreet-parcel::before {
                background-position: -190px center;
            }
            &.specialist-support::before {
                background-position: -380px center;
            }
        }
        .caption {
            padding: 0;
        }
        .links {
            padding: 20px 0;
            .more {
                display: inline-block;
                &:nth-child(1),
                &:nth-child(2) {
                    margin-right: 20px;
                }
            }
        }
    }
    @media (min-width: $screen-md-min) {
        .item {
            height: 230px;
            width: 230px;
            &.discreet-parcel::before {
                background-position: -210px center;
            }
            &.specialist-support::before {
                background-position: -420px center;
            }
        }
        .caption {
            padding: 0 30px;
        }
        .links {
            .more {
                &:nth-child(1),
                &:nth-child(2) {
                    margin-right: 100px;
                }
            }
        }
    }
}