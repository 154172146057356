@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.preloader {
    @include transition-duration(0.5s);
    @include transition-property(bottom, visibility);
    @include transition-timing(cubic-bezier(0.6, -0.28, 0.74, 0.05));
    background-color: $preloader-background-color;
    bottom: 0%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: visible;
    z-index: 9999;
    .spinner {
        @include animation-duration(1.6s);
        @include animation-iteration-count(infinite);
        @include animation-name(spin);
        @include animation-timing-function(ease);
        border-bottom: 4px solid $preloader-color-3;
        border-radius: 50%;
        bottom: 0;
        height: 200px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 200px;
        &::after {
            background-color: $spyshop-background-color-primary;
            background-image: url($image-path + "preloader.png");
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50%;
            bottom: 0;
            content: "";
            height: 160px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 160px;
        }
    }
    &.disable {
        bottom: 100%;
        visibility: hidden;
    }
}