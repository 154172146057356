.navbar-inverse {
    background-color: $background-color-default;
    border-style: none;
    box-shadow: 0 1px 2px rgba($background-color-default, 0.6);
    @include transition-delay(0s);
    @include transition-duration(0.5s);
    @include transition-property(padding);
    @include transition-timing(ease-in-out);
    .menu {
        position: relative;
    }
    .navbar-toggle {
        border-color: $color-primary;
        border-radius: 2px;
        border-width: 2px;
        .icon-bar {
            background-color: $background-color-primary;
        }
        &:focus,
        &:hover {
            background-color: $background-color-primary;
            .icon-bar {
                background-color: $background-color-white;
            }
        }
    }
    .navbar-nav {
        @media (min-width: $screen-sm-min) {
            margin: 0 auto;
        }
        @media (min-width: $screen-md-min) {
            margin-right: -15px;
        }
        >li {
            >a {
                color: $link-color-menu;
                font-size: 16px;
                text-align: center;
                text-transform: uppercase;
                &:focus,
                &:hover {
                    color: $link-hover-color-menu;
                }
                @media (min-width: $screen-sm-min) {
                    font-size: 15px;
                    padding: 15px 10px;
                }
                @media (min-width: $screen-md-min) {
                    font-size: 16px;
                    padding: 15px;
                }
            }
        }
        .active {
            >a {
                background-color: $background-color-primary;
                color: $link-color-menu;
                &:focus,
                &:hover {
                    background-color: $background-color-primary;
                    color: $link-color-menu;
                }
            }
        }
        .dropdown-menu {
            background-color: $background-color-menu;
            @media (min-width: $screen-sm-min) {
                background-color: $background-color-default;
                top: 98%;
            }
            >li {
                >a {
                    color: $link-color-menu !important;
                    font-size: 15px;
                    padding: 10px 15px;
                    text-align: center;
                    text-transform: uppercase;
                    @media (min-width: $screen-sm-min) {
                        padding: 10px 15px;
                        text-align: left;
                    }
                    &:hover,
                    &:focus {
                        background-color: transparent;
                        color: $link-hover-color-menu !important;
                    }
                }
            }
        }
    }
    @media (min-width: $screen-sm-min) {
        padding-bottom: 10px;
        padding-top: 50px;
        &.navbar-shrink {
            padding-bottom: 0;
            padding-top: 0;
        }
    }
}

.navbar-contact {
    position: absolute;
    right: 80px;
    top: 11px;
    @media (min-width: $screen-sm-min) {
        right: 15px;
        top: -30px;
    }
    .navbar-shrink & {
        @media (min-width: $screen-md-min) {
            @include transition-delay(0s);
            @include transition-duration(0.4s);
            @include transition-property(all);
            @include transition-timing(ease-in-out);
            left: 70px;
            right: auto;
            top: 10px;
            a {
                span {
                    display: none;
                }
            }
        }
    }
    ul {
        list-style-image: none;
        list-style-position: outside;
        list-style-type: none;
        margin: 0;
        overflow: hidden;
        padding: 0;
        li {
            display: inline-block;
            padding-right: 15px;
            &:nth-last-child(1) {
                padding-right: 0;
            }
        }
    }
    a {
        display: block;
        font-size: 12px;
        @media (min-width: $screen-sm-min) {
            font-size: 15px;
        }
        i {
            background-color: $background-color-primary;
            border-radius: 50%;
            color: $color-default;
            font-size: 16px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            vertical-align: middle;
            width: 30px;
            @media (min-width: $screen-sm-min) {
                font-size: 16px;
                height: 30px;
                line-height: 30px;
                width: 30px;
            }
            &.fa-envelope-o {
                font-size: 11px;
            }
        }
        span {
            display: none;
            margin-left: 5px;
            @media (min-width: $screen-sm-min) {
                display: initial;
            }
        }
    }
}

.navbar-header {
    h1 {
        margin: 0;
    }
    .navbar-brand {
        img {
            height: 35px;
            max-height: 100%;
            width: auto;
            @media (min-width: $screen-md-min) {
                height: auto;
            }
        }
    }
    .logo {
        opacity: 0;
        z-index: -1;
        @media (min-width: $screen-sm-min) {
            @include transition-delay(0s);
            @include transition-duration(0.2s);
            @include transition-property(all);
            @include transition-timing(ease-in-out);
            height: auto;
            opacity: 1;
            padding: 0 15px;
            position: absolute;
            top: -32px;
            z-index: 0;
            .navbar-shrink & {
                opacity: 0;
                z-index: -1;
            }
        }
        @media (min-width: $screen-md-min) {
            top: -26px;
        }
    }
    .logo-fixed {
        bottom: auto;
        height: auto;
        left: 15px;
        padding: 0;
        position: absolute;
        top: 8px;
        @media (min-width: $screen-sm-min) {
            left: 15px;
            opacity: 0;
            z-index: -1;
        }
        .navbar-shrink & {
            @media (min-width: $screen-md-min) {
                @include transition-delay(0.5s);
                @include transition-duration(0.2s);
                @include transition-property(all);
                @include transition-timing(ease-in-out);
                left: 30px;
                opacity: 1;
                z-index: 0;
            }
        }
        img {
            height: 35px;
        }
    }
}