.breathalyzers {
    background-color: $background-color-default;
    margin-left: 15px;
    margin-right: 15px;
    padding: 40px 30px;
    position: relative;
    text-align: center;
    &::before {
        @include box-shadow-left(5%, 45.2%);
    }
    &::after {
        @include box-shadow-right(5%, 45.2%);
    }
    h3 {
        color: $text-color-primary;
        margin-top: 0;
    }
    p {
        color: $text-color-white;
        font-size: 15px;
    }
    ul {
        list-style-image: none;
        list-style-position: inside;
        list-style-type: square;
        margin: 0;
        padding: 0 0 0 20px;
        li {
            color: $text-color-white;
            font-size: 15px;
        }
    }
    @media (min-width: $screen-sm-min) {
        background-image: url($image-path + "breathalyzers-bg.jpg");
        background-position: -285px center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: auto;
        margin-right: auto;
        padding: 40px 30px 40px 270px;
        text-align: left;
        h3 {
            text-align: left;
        }
    }
    @media (min-width: $screen-md-min) {
        background-position: -120px center;
        padding: 40px 30px 40px 380px;
    }
    @media (min-width: $screen-lg-min) {
        background-position: left center;
        padding: 40px 30px 40px 480px;
    }
}