footer {
    text-align: center;
    .social {
        margin-bottom: 20px;
        i {
            @include transition-delay(0s);
            @include transition-duration(0.1s);
            @include transition-property(background-color);
            @include transition-timing(ease-in-out);
            background-color: $background-color-default;
            border-radius: 50%;
            color: $text-color-white;
            font-size: 26px;
            height: 56px;
            line-height: 56px;
            margin: 0 5px;
            width: 56px;
            &:hover {
                background-color: $background-color-primary;
                color: $text-color-default;
            }
        }
    }
    .footer-menu {
        margin: 0 0 10px 0;
        padding: 0;
        li {
            display: inline-block;
            vertical-align: middle;
            a {
                display: block;
                &:hover {
                    color: $text-color-default;
                }
            }
        }
    }
    .copyright {
        margin-bottom: 20px;
        a {
            display: block;
            &:hover {
                color: $text-color-default;
            }
        }
    }
}