.best-products {
    margin-bottom: 30px;
    overflow: hidden;
    text-align: center;
    &:nth-last-child(1) {
        margin-bottom: 0;
    }
    h4 {
        color: $text-color-default;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
        text-transform: uppercase;
    }
    span {
        color: $text-color-primary;
        font-size: 15px;
        font-weight: 400;
    }
    .img {
        background-color: $background-color-primary;
        border-radius: 50%;
        height: 290px;
        margin-bottom: 30px;
        margin-top: 20px;
        width: 290px;
        img {
            position: relative;
            top: -10px;
            &[src="img/alcotest-6820-desktop.png"] {
                left: -25px;
            }
            &[src="img/alp1-desktop.png"] {
                left: 3px;
            }
            &[src="img/drager-alcotest-7510-desktop.png"] {
                left: 4px;
            }
        }
    }
    .star-rating {
        border-bottom: 1px solid $star-rating-border;
        border-top: 1px solid $star-rating-border;
        margin: 30px 40px 10px;
        padding: 10px 0px;
        i {
            font-size: 22px;
            color: $star-rating-bg-primary;
            &.disabled {
                color: $star-rating-bg;
            }
        }
    }
    .features {
        list-style-image: none;
        list-style-position: inside;
        list-style-type: square;
        padding: 0;
        li {
            color: $text-color-default;
            font-size: 15px;
        }
    }
    .price {
        color: $text-color-primary;
        font-size: 46px;
        line-height: 1.1;
        .currency {
            font-size: 30px;
        }
    }
    .buy {
        background-color: $background-color-default;
        border-radius: 0px;
        border-width: 0px;
        color: $text-color-primary;
        font-size: 35px;
        margin-top: 5px;
        padding: 5px 50px;
    }
    .more {
        a {
            color: $more-color;
            display: block;
            font-size: 15px;
            margin-top: 5px;
        }
    }
    @media (min-width: $screen-sm-min) {
        margin: 0;
        overflow: visible;
        .img {
            height: 214px;
            width: 214px;
            img {
                top: -10px;
            }
        }
    }
}

.slider-products {
    .slider-products-control {
        display: none;
    }
    @media (min-width: $screen-sm-min) {
        overflow: hidden;
        position: relative;
        .slider-products-inner {
            position: relative;
        }
        .slider-products-control {
            background-color: $background-color-default;
            border-radius: 50%;
            color: $text-color-white;
            font-size: 56px;
            height: 48px;
            line-height: 36px;
            position: absolute;
            text-align: center;
            top: 154px;
            width: 48px;
            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }
            &.left {
                left: 0;
            }
            &.right {
                right: 0;
            }
        }
        &.active {
            .slider-products-control {
                display: block;
            }
        }
    }
}