/* Media queries */

$screen-xs-max: 480px; // Extra small devices (mobile phones, 480px and up)
$screen-sm-min: 768px; // Small devices (tablets, 768px and up)
$screen-md-min: 992px; // Medium devices (desktops, 992px and up)
$screen-lg-min: 1200px; // Large devices (large desktops, 1200px
$screen-custom-max: 479px; // Custom device

/* Paths to images */

$image-path: "../img/";

/* Colors */

$article-link-hover-color: #000000;
$background-color-default: #000000;
$background-color-menu: #333333;
$background-color-primary: #ff8b00;
$background-color-white: #fff;
$color-default: #000;
$color-primary: #ff8b00;
$color-white: #fff;
$link-color-menu: #ffffff;
$link-color: #ff8b00;
$link-hover-color-menu: #ff8b00;
$link-hover-color: #ffffff;
$more-color: #737373;

/* Preloader */

$preloader-background-color: #000000;
$preloader-color-1: #ffffff;
$preloader-color-2: #ffffff;
$preloader-color-3: #ffffff;

/* Spy Shop */

$spyshop-background-color-primary: #f06e00;

/* Star rating */

$star-rating-bg-primary: #ff8b00;
$star-rating-bg: #cdcdcd;
$star-rating-border: #cdcdcd;
$text-color-default: #000;
$text-color-primary: #ff8b00;
$text-color-white: #fff;

/* Cookie info */

$cookie-background-color: $background-color-default;
$cookie-close-notice: #ffffff;
$cookie-icon: $color-primary;
$cookie-text-color: #ffffff;

/* Typography */

$font-family-sans-serif: "Lato",
Helvetica,
Arial,
sans-serif;