@import "partials/variables";
@import "partials/mixins";
@import "partials/layout";
@import "partials/nav";
@import "partials/slider";
@import "partials/best-products";
@import "partials/breathalyzers";
@import "partials/testimonials";
@import "partials/our-advantages";
@import "partials/article";
@import "partials/footer";
@import "partials/preloader";
@import "partials/cookie";