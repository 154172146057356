body {
    font-family: $font-family-sans-serif;
    font-size: 16px;
    position: relative;
}

a {
    color: $link-color;
    &:focus,
    &:hover {
        color: $link-hover-color;
    }
}

section {
    margin-bottom: 50px;
}

.section-heading {
    color: $text-color-default;
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
    word-break: break-all;
    span {
        display: block;
        font-size: 26px;
        text-transform: none;
    }
}

.embed-responsive {
    margin: 30px 0;
}

noscript {
    color: $color-white;
    display: block;
    margin-top: 0px;
    margin-top: 15px;
    margin: 0 auto;
    padding: 15px;
    text-align: center;
}